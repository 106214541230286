.slider-container .slick-dots {
    bottom: -30px;
  }
  
  .slider-container .slick-dots li button:before {
    font-size: 10px;
    color: #f97316;
  }
  
  .slider-container .slick-dots li.slick-active button:before {
    color: #f97316;
  }
  
  @media (max-width: 640px) {
    .slider-container .slick-dots {
      bottom: -20px;
    }
  }
  
  