@tailwind base;
@tailwind components;
@tailwind utilities;


.span_text_blue{
    color: #1DD1E1;
    font-family: 'roboto';
}

.react-tabs__tab--selected {
    background: #020764 !important;
    border-color: #aaa;
    color: white !important;
    border-radius: 5px 5px 0 0;
}

.service_card_box{
    box-shadow: 1px 1px 15px #020764;
}

.img_filter{
    filter: invert(1);
}

.contact_Card_shadow {
    box-shadow: 0px 1px 8px 0px;
}

.raing_card {
    background: white;
    padding: 50px;
    border: 1px solid;
    border-radius: 5px;
    box-shadow: 0px 2px 8px #020764;
    font-size: 19px;
    font-family: 'Roboto';
}

@media only screen and (max-width: 668px) {
   .navbarshadow {
        box-shadow: 0px 4px 4px;
    }
}